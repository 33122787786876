import * as THREE from 'three'
import { proxy } from 'valtio'

export const damp = THREE.MathUtils.damp

export const imageUrls = ["https://littleghosts.com", 'https://ghostswap.finance', 'https://bbits.art', 'https://phantasma.online', 'https://ghostlabs.miami'];
export const state = proxy({
  clicked: null,
  urls: [1, 2, 3, 4, 5].map((u) => `/${u}.png`),
})
